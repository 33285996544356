import HeaderMobile from '@/entities/section/fixtures/headerMobile/HeaderMobile';
import fixtures from '@/entities/section/fixtures/headerMobile/fixtures';

const items = {} as { [key: string]: any };
for (const key in fixtures) {
    items[key] = new HeaderMobile(fixtures[key]);
}

const itemsForSelect = Object.keys(items).map((key) => {
    return { title: key, preview: items[key].preview, tags: items[key].tags };
});

export { items, itemsForSelect };
