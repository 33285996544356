import store from '@/store/store';
import icons from '@/entities/icons';
const components = {
    Info: {
        text: {
            component: 'InfoText',
            props: { text: 'Some text here' },
        },
        address: {
            component: 'InfoAddress',
        },
        socials: {
            component: 'InfoSocials',
        },
        phone: {
            text: 'Phone',
        },
    },
    Main: {
        buttonPopup: {
            component: 'MainButton',
            props: {
                button: {
                    isShow: true,
                    styles: 'secondary',
                    text: 'Buy now',
                    textColor: null,
                    bgColor: null,
                    iconLeft: null,
                    iconRight: null,
                    type: 'popup',
                    url: null,
                },
            },
        },
        buttonLink: {
            component: 'MainButton',
            props: {
                button: {
                    isShow: true,
                    styles: 'secondary',
                    text: 'Go to main',
                    textColor: null,
                    bgColor: null,
                    link: null,
                    iconLeft: null,
                    iconRight: null,
                    type: 'link',
                    url: { type: 'homepage', id: null, title: 'Homepage', url: '/' },
                },
            },
        },
        account: {
            component: 'MainAccount',
        },
        search: {
            component: 'MainSearch',
        },
        menu: {
            component: 'MainMenu',
        },
        phone: {
            component: 'MainPhone',
        },
        cart: {
            component: 'MainCart',
        },
        socialsFacebook: {
            component: 'MainSocials',
            props: { social: 'facebook' },
        },
        socialsInstagram: {
            component: 'MainSocials',
            props: { social: 'instagram' },
        },
        socialsX: { component: 'MainSocials', props: { social: 'twitter' } },
        socialsLinkedIn: {
            component: 'MainSocials',
            props: { social: 'linkedIn' },
        },
        socialsMedium: { component: 'MainSocials', props: { social: 'medium' } },
        socialsTelegram: {
            component: 'MainSocials',
            props: { social: 'telegram' },
        },
        socialsTikTok: { component: 'MainSocials', props: { social: 'tiktok' } },
        socialsVK: { component: 'MainSocials', props: { social: 'vk' } },
    },
    Second: {
        buttonPhone: {
            component: 'MainButton',
            props: {
                button: {
                    isShow: true,
                    styles: 'secondary',
                    text: store.getters['builder/getSettings'].common.phones.value[0],
                    value: store.getters['builder/getSettings'].common.phones.value[0],
                    textColor: null,
                    bgColor: null,
                    iconLeft: null,
                    iconRight: null,
                    type: 'phone',
                    url: null,
                },
            },
        },
        buttonWhatsapp: {
            component: 'MainButton',
            props: {
                button: {
                    isShow: true,
                    styles: 'secondary',
                    text: store.getters['builder/getSettings'].common.whatsapps.value[0],
                    textColor: null,
                    bgColor: null,
                    value: store.getters['builder/getSettings'].common.whatsapps.value[0],
                    iconLeft: null,
                    iconRight: null,
                    type: 'whatsapp',
                    url: null,
                },
            },
        },
        buttonLink: {
            component: 'MainButton',
            props: {
                button: {
                    isShow: true,
                    styles: 'secondary',
                    text: 'Go to main',
                    textColor: null,
                    bgColor: null,
                    iconLeft: null,
                    iconRight: null,
                    type: 'link',
                    url: { type: 'homepage', id: null, title: 'Homepage', url: '/' },
                },
            },
        },
        buttonPopup: {
            component: 'MainButton',
            props: {
                button: {
                    isShow: true,
                    styles: 'secondary',
                    text: 'Buy now',
                    textColor: null,
                    bgColor: null,
                    iconLeft: null,
                    iconRight: null,
                    type: 'popup',
                    url: null,
                },
            },
        },
        email: {
            component: 'SecondEmail',
        },
        telegram: {
            component: 'SecondTelegram',
        },
        whatsapp: {
            component: 'SecondWhatsapp',
        },
        search: { component: 'SecondSearch' },
    },
};

const newStructureForHeaderItems = [
    {
        title: 'customText', // $t(builder.header.items.title)
        component: 'Select',
        type: 'Info',
        templateComponent: 'InfoText',
        customization: {
            component: 'CodeEditor',
            valueName: 'text',
            default: { text: '' },
            props: {},
        },
    },
    { title: 'menu', type: 'Info', component: 'Select', templateComponent: 'InfoMenu', customization: {} },
    { title: 'address', component: 'Select', templateComponent: 'InfoAddress', customization: {} },
    {
        title: 'socials',
        type: 'Info',
        // $t(builder.header.items.title)
        component: 'Select',
        templateComponent: 'InfoSocials',
        customization: {},
    },
    {
        title: 'phone', // $t(builder.header.items.title)
        component: 'Select',
        type: 'Info',
        templateComponent: 'InfoPhone',
        customization: {},
    },
    {
        title: 'button',
        templateComponent: 'MainButton',
        type: 'Main',
        customization: {
            component: 'ButtonBuilder',
            button: {},
            valueName: 'button',
            props: {
                types: [
                    { text: 'Link', value: 'link' },
                    { text: 'Popup', value: 'popup' },
                    { text: 'Phone', value: 'phone' },
                    { text: 'Whatsapp', value: 'whatsapp' },
                ],
                icons: [...icons],
                typesAsSelect: true,
            },
        },
    },
    {
        title: 'account', // $t(builder.header.items.title)
        component: 'Select',
        type: 'Main',
        templateComponent: 'MainAccount',
        customization: {},
    },
    { title: 'menu', component: 'Select', type: 'Main', templateComponent: 'MainMenu', customization: {} },
    {
        title: 'search',
        component: 'Select',
        type: 'Main',
        templateComponent: 'MainSearch',
        customization: {},
    },

    {
        title: 'phone',
        component: 'Select',
        type: 'Main',
        templateComponent: 'MainPhone',
        customization: {},
    },
    {
        title: 'cart',
        component: 'Select',
        type: 'Main',
        templateComponent: 'MainCart',
        customization: {},
    },
    {
        title: 'socials',
        component: 'Select',
        type: 'Main',

        templateComponent: 'MainSocials',
        customization: {
            component: 'Select',
            default: { ...components.Main.socialsFacebook.props },
            variants: {
                facebook: { ...components.Main.socialsFacebook.props },
                whatsapp: { ...components.Main.socialsInstagram.props },
                twitter: { ...components.Main.socialsX.props },
                telegram: { ...components.Main.socialsTelegram.props },
                linkedIn: { ...components.Main.socialsLinkedIn.props },
                medium: { ...components.Main.socialsMedium.props },
                tikTok: { ...components.Main.socialsTikTok.props },
                VK: { ...components.Main.socialsVK.props },
            },
            props: {},
        },
    },
    {
        title: 'email',
        component: 'Select',
        type: 'Second',
        templateComponent: 'SecondEmail',
        customization: {},
    },
    {
        title: 'telegram',
        component: 'Select',
        type: 'Second',
        templateComponent: 'SecondTelegram',
        customization: {},
    },
    {
        title: 'whatsapp',
        component: 'Select',
        type: 'Second',
        templateComponent: 'SecondWhatsapp',
        customization: {},
    },
    {
        title: 'search',
        type: 'Second',
        templateComponent: 'SecondSearch',
        customization: {
            component: 'InputText',
            props: {
                disabled: true,
                placeholder: 'settings.header.templateItems.search',
            },
        },
    },
];

export default (() => {
    // sections.push(SectionTestList);

    const getItemsComponents = () => newStructureForHeaderItems.map((item) => item.component);
    const getItemsForComponentSelect = (type: string) =>
        newStructureForHeaderItems
            .filter((item) => item.component === 'Select' && item.type === type)
            .map((item) => {
                return { value: item.templateComponent, text: `settings.header.templateItems.${item.title}` };
            });

    const getItemByTemplateComponent = (component: string) => {
        return newStructureForHeaderItems.find((item) => item.templateComponent === component);
    };

    return Object.freeze({
        getItemsComponents,
        getItemsForComponentSelect,
        getItemByTemplateComponent,
        components,
    });
})();
