import Header from '@/entities/section/fixtures/headerDesktop/Header';
import defaultProps from '@/entities/section/fixtures/headerMobile/defaultProps';
import { capitalize } from '@/helpers/filters';
import { cloneDeep } from 'lodash';
export default class HeaderMobile extends Header {
    constructor(args: {
        preview: string;
        title: string;
        template: any;
        onScroll: 'sticky' | 'static';
        tags: string[];
        locate: string;
    }) {
        super({
            ...args,
            preview: args.preview || require(`@/assets/images/headers-mobile/${args.title}.png`),
        });
    }

    static defaultProps: any = defaultProps;

    createTagsAndTemplate(template: { [key: string]: any }) {
        const itemsToIncludeAsTags = {
            Info: { text: 'Text', phone: 'Phone', address: 'Address', socials: 'Socials' },
            Main: {
                search: 'Search',
                phone: 'Phone',
                cart: 'Cart',
                menu: 'Menu',
                account: 'Account',
                socialsFacebook: 'Socials',
                socialsInstagram: 'Socials',
                socialsX: 'Socials',
                socialsLinkedIn: 'Socials',
                socialsMedium: 'Socials',
                socialsTelegram: 'Socials',
                socialsTikTok: 'Socials',
                socialsVK: 'Socials',
                buttonPhone: 'Phone button',
                buttonPopup: 'Popup button',
                buttonLink: 'Button with link',
                buttonWhatsapp: 'Whatsapp button',
            },
            Second: {
                buttonPhone: 'Phone button',
                buttonPopup: 'Popup button',
                buttonLink: 'Button with link',
                buttonWhatsapp: 'Whatsapp button',
                email: 'Email',
                whatsapp: 'Socials',
                telegram: 'Socials',
                search: 'Search',
            },
        } as { [key: string]: { [key: string]: string } };
        template.forEach((level: any) => {
            const newTemplateItem = {
                ...level,
                items: [],
            };
            level.items.forEach((item: string) => {
                let newComponentItem = cloneDeep(HeaderMobile.defaultProps.components[level.type][item]);
                if (!newComponentItem) {
                    newComponentItem = { component: `${level.type}${capitalize(item)}` };
                }
                const tag = itemsToIncludeAsTags[level.type][item];
                if (tag && !this.tags.includes(tag)) {
                    this.tags.push(itemsToIncludeAsTags[level.type][item]);
                }
                newTemplateItem.items.push(newComponentItem);
            });
            this.template.push(newTemplateItem);
        });
    }

    static stylesPath = { path: ['styles'], scrollTo: ['header', 'mobile'] };
}
