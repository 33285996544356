export default {
    default1: {
        title: 'default1',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: 'logo_4',
                items: ['phone', 'account', 'cart', 'menu'],
            },
        ],
    },
    default2: {
        title: 'default2',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: 'logo_3',
                items: ['account', 'cart', 'menu'],
            },
            {
                type: 'Second',
                template: 'button_3',
                items: ['buttonPhone', 'whatsapp', 'telegram', 'email'],
            },
        ],
    },
    'mh-010-i-0001': {
        title: 'mh-010-i-0001',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: '1_logo_1',
                items: ['phone', 'menu'],
            },
        ],
    },
    'mh-010-i-0002': {
        title: 'mh-010-i-0002',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: '1_logo_2',
                items: ['phone', 'cart', 'menu'],
            },
        ],
    },
    'mh-010-i-0003': {
        title: 'mh-010-i-0003',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: '2_logo_2',
                items: ['menu', 'search', 'account', 'cart'],
            },
        ],
    },
    'mh-010-i-0004': {
        title: 'mh-010-i-0004',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: '1_logo_button',
                items: ['menu', 'buttonPopup'],
            },
        ],
    },
    'mh-010-i-0005': {
        title: 'mh-010-i-0005',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: 'logo_button_1',
                items: ['buttonPopup', 'menu'],
            },
        ],
    },
    'mh-010-i-0006': {
        title: 'mh-010-i-0006',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: 'logo_1',
                items: ['menu'],
            },
        ],
    },
    'mh-010-i-0007': {
        title: 'mh-010-i-0007',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: 'logo_2',
                items: ['phone', 'menu'],
            },
        ],
    },
    'mh-010-i-0008': {
        title: 'mh-010-i-0008',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: 'logo_3',
                items: ['account', 'cart', 'menu'],
            },
        ],
    },
    'mh-010-i-0009': {
        title: 'mh-010-i-0009',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: 'logo_4',
                items: ['phone', 'socialsTelegram', 'search', 'menu'],
            },
        ],
    },
    'mh-010-i-0010': {
        title: 'mh-010-i-0010',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: 'logo_5',
                items: ['socialsFacebook', 'socialsInstagram', 'socialsLinkedIn', 'socialsX', 'menu'],
            },
        ],
    },
    'mh-010-i-0011': {
        title: 'mh-010-i-0011',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: '1_logo_1',
                items: ['search', 'menu'],
            },
            {
                type: 'Second',
                template: 'column_1',
                items: ['buttonPhone'],
            },
        ],
    },
    'mh-010-i-0012': {
        title: 'mh-010-i-0012',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: '2_logo_2',
                items: ['menu', 'search', 'account', 'cart'],
            },
            {
                type: 'Second',
                template: 'row',
                items: ['buttonPhone', 'buttonWhatsapp'],
            },
        ],
    },
    'mh-010-i-0013': {
        title: 'mh-010-i-0013',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: '1_logo_2',
                items: ['phone', 'search', 'menu'],
            },
            {
                type: 'Second',
                template: 'column_1',
                items: ['buttonPopup'],
            },
        ],
    },
    'mh-010-i-0014': {
        title: 'mh-010-i-0014',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: 'logo_button_1',
                items: ['buttonPopup', 'menu'],
            },
            {
                type: 'Second',
                template: 'column_1',
                items: ['search'],
            },
        ],
    },
    'mh-010-i-0015': {
        title: 'mh-010-i-0015',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: 'logo_2',
                items: ['cart', 'menu'],
            },
            {
                type: 'Second',
                template: 'button_3',
                items: ['buttonPhone', 'whatsapp', 'telegram', 'email'],
            },
        ],
    },
    'mh-010-i-0016': {
        title: 'mh-010-i-0016',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: 'logo_3',
                items: ['account', 'cart', 'menu'],
            },
            {
                type: 'Second',
                template: 'button_3',
                items: ['buttonPopup', 'phone', 'whatsapp', 'email'],
            },
        ],
    },
    'mh-110-i-0001': {
        title: 'mh-110-i-0001',
        locate: 'inline',
        template: [
            {
                type: 'Info',
                template: 'center_1',
                items: ['socials'],
            },
            {
                type: 'Main',
                template: '1_logo_1',
                items: ['phone', 'menu'],
            },
        ],
    },
    'mh-110-i-0002': {
        title: 'mh-110-i-0002',
        locate: 'inline',
        template: [
            {
                type: 'Info',
                template: 'center_1',
                items: ['text'],
            },
            {
                type: 'Main',
                template: '1_logo_1',
                items: ['phone', 'menu'],
            },
        ],
    },
    'mh-110-i-0003': {
        title: 'mh-110-i-0003',
        locate: 'inline',
        template: [
            {
                type: 'Info',
                template: 'center_1',
                items: ['phone'],
            },
            {
                type: 'Main',
                template: 'logo_button_1',
                items: ['buttonPopup', 'menu'],
            },
        ],
    },
    'mh-110-i-0004': {
        title: 'mh-110-i-0004',
        locate: 'inline',
        template: [
            {
                type: 'Info',
                template: 'center_1',
                items: ['socials'],
            },
            {
                type: 'Main',
                template: 'logo_button_1',
                items: ['buttonPopup', 'menu'],
            },
        ],
    },
    'mh-110-i-0005': {
        title: 'mh-110-i-0005',
        locate: 'inline',
        template: [
            {
                type: 'Info',
                template: 'center_1',
                items: ['text'],
            },
            {
                type: 'Main',
                template: 'logo_button_1',
                items: ['buttonPopup', 'menu'],
            },
        ],
    },
    'mh-110-i-0006': {
        title: 'mh-110-i-0006',
        locate: 'inline',
        template: [
            {
                type: 'Info',
                template: 'center_1',
                items: ['socials'],
            },
            {
                type: 'Main',
                template: '2_logo_2',
                items: ['menu', 'phone', 'account', 'cart'],
            },
        ],
    },
    'mh-110-i-0007': {
        title: 'mh-110-i-0007',
        locate: 'inline',
        template: [
            {
                type: 'Info',
                template: 'center_1',
                items: ['phone'],
            },
            {
                type: 'Main',
                template: '2_logo_2',
                items: ['menu', 'search', 'account', 'cart'],
            },
        ],
    },
    'mh-110-i-0008': {
        title: 'mh-110-i-0008',
        locate: 'inline',
        template: [
            {
                type: 'Info',
                template: 'center_1',
                items: ['text'],
            },
            {
                type: 'Main',
                template: '2_logo_2',
                items: ['phone', 'search', 'cart', 'menu'],
            },
        ],
    },
    'mh-110-i-0009': {
        title: 'mh-110-i-0009',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: '1_logo_1',
                items: ['phone', 'menu'],
            },
            {
                type: 'Info',
                template: 'center_1',
                items: ['socials'],
            },
        ],
    },
    'mh-110-i-0010': {
        title: 'mh-110-i-0010',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: '1_logo_1',
                items: ['phone', 'menu'],
            },
            {
                type: 'Info',
                template: 'center_1',
                items: ['text'],
            },
        ],
    },
    'mh-110-i-0011': {
        title: 'mh-110-i-0011',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: 'logo_button_1',
                items: ['buttonPopup', 'menu'],
            },
            {
                type: 'Info',
                template: 'center_1',
                items: ['phone'],
            },
        ],
    },
    'mh-110-i-0012': {
        title: 'mh-110-i-0012',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: 'logo_button_1',
                items: ['buttonPopup', 'menu'],
            },
            {
                type: 'Info',
                template: 'center_1',
                items: ['socials'],
            },
        ],
    },
    'mh-110-i-0013': {
        title: 'mh-110-i-0013',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: 'logo_button_1',
                items: ['buttonPopup', 'menu'],
            },
            {
                type: 'Info',
                template: 'center_1',
                items: ['text'],
            },
        ],
    },
    'mh-110-i-0014': {
        title: 'mh-110-i-0014',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: '2_logo_2',
                items: ['menu', 'phone', 'account', 'cart'],
            },
            {
                type: 'Info',
                template: 'center_1',
                items: ['socials'],
            },
        ],
    },
    'mh-110-i-0015': {
        title: 'mh-110-i-0015',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: '2_logo_2',
                items: ['menu', 'search', 'account', 'cart'],
            },
            {
                type: 'Info',
                template: 'center_1',
                items: ['phone'],
            },
        ],
    },
    'mh-110-i-0016': {
        title: 'mh-110-i-0016',
        locate: 'inline',
        template: [
            {
                type: 'Main',
                template: '2_logo_2',
                items: ['search', 'phone', 'cart', 'menu'],
            },
            {
                type: 'Info',
                template: 'center_1',
                items: ['text'],
            },
        ],
    },
    'mh-110-i-0017': {
        title: 'mh-110-i-0017',
        locate: 'inline',
        template: [
            {
                type: 'Info',
                template: 'center_1',
                items: ['phone'],
            },
            {
                type: 'Main',
                template: '1_logo_1',
                items: ['search', 'menu'],
            },
            {
                type: 'Second',
                template: 'column_1',
                items: ['buttonPopup'],
            },
        ],
    },
    'mh-110-i-0018': {
        title: 'mh-110-i-0018',
        locate: 'inline',
        template: [
            {
                type: 'Info',
                template: 'center_1',
                items: ['address'],
            },
            {
                type: 'Main',
                template: '2_logo_2',
                items: ['menu', 'phone', 'account', 'cart'],
            },
            {
                type: 'Second',
                template: 'column_2',
                items: ['buttonPopup', 'buttonPhone'],
            },
        ],
    },
    'mh-110-i-0019': {
        title: 'mh-110-i-0019',
        locate: 'inline',
        template: [
            {
                type: 'Info',
                template: 'center_1',
                items: ['socials'],
            },
            {
                type: 'Main',
                template: '1_logo_2',
                items: ['phone', 'search', 'menu'],
            },
            {
                type: 'Second',
                template: 'column_1',
                items: ['buttonPopup'],
            },
        ],
    },
    'mh-110-i-0020': {
        title: 'mh-110-i-0020',
        locate: 'inline',
        template: [
            {
                type: 'Info',
                template: 'center_1',
                items: ['text'],
            },
            {
                type: 'Main',
                template: 'logo_button_1',
                items: ['buttonPopup', 'menu'],
            },
            {
                type: 'Second',
                template: 'column_1',
                items: ['search'],
            },
        ],
    },
    'mh-010-c-0001': {
        title: 'mh-010-c-0001',
        locate: 'centred',
        template: [
            {
                type: 'Main',
                template: '1_logo_1',
                items: ['phone', 'menu'],
            },
        ],
    },
    'mh-010-c-0002': {
        title: 'mh-010-c-0002',
        locate: 'centred',
        template: [
            {
                type: 'Main',
                template: '1_logo_2',
                items: ['phone', 'cart', 'menu'],
            },
        ],
    },
    'mh-010-c-0003': {
        title: 'mh-010-c-0003',
        locate: 'centred',
        template: [
            {
                type: 'Main',
                template: '2_logo_2',
                items: ['menu', 'search', 'account', 'cart'],
            },
        ],
    },
    'mh-010-c-0004': {
        title: 'mh-010-c-0004',
        locate: 'centred',
        template: [
            {
                type: 'Main',
                template: '1_logo_button',
                items: ['menu', 'buttonPopup'],
            },
        ],
    },
    'mh-010-c-0005': {
        title: 'mh-010-c-0005',
        locate: 'centred',
        template: [
            {
                type: 'Main',
                template: 'logo_button_1',
                items: ['buttonPopup', 'menu'],
            },
        ],
    },
    'mh-010-c-0006': {
        title: 'mh-010-c-0006',
        locate: 'centred',
        template: [
            {
                type: 'Main',
                template: 'logo_1',
                items: ['menu'],
            },
        ],
    },
    'mh-010-c-0007': {
        title: 'mh-010-c-0007',
        locate: 'centred',
        template: [
            {
                type: 'Main',
                template: 'logo_2',
                items: ['phone', 'menu'],
            },
        ],
    },
    'mh-010-c-0008': {
        title: 'mh-010-c-0008',
        locate: 'centred',
        template: [
            {
                type: 'Main',
                template: 'logo_3',
                items: ['account', 'cart', 'menu'],
            },
        ],
    },
    'mh-010-c-0009': {
        title: 'mh-010-c-0009',
        locate: 'centred',
        template: [
            {
                type: 'Main',
                template: 'logo_4',
                items: ['phone', 'socialsTelegram', 'search', 'menu'],
            },
        ],
    },
    'mh-010-c-0010': {
        title: 'mh-010-c-0010',
        locate: 'centred',
        template: [
            {
                type: 'Main',
                template: 'logo_5',
                items: ['socialsFacebook', 'socialsInstagram', 'socialsLinkedIn', 'socialsX', 'menu'],
            },
        ],
    },
    'mh-010-c-0011': {
        title: 'mh-010-c-0011',
        locate: 'centred',
        template: [
            {
                type: 'Main',
                template: '1_logo_1',
                items: ['search', 'menu'],
            },
            {
                type: 'Second',
                template: 'column_1',
                items: ['buttonPhone'],
            },
        ],
    },
    'mh-010-c-0012': {
        title: 'mh-010-c-0012',
        locate: 'centred',
        template: [
            {
                type: 'Main',
                template: '2_logo_2',
                items: ['menu', 'search', 'account', 'cart'],
            },
            {
                type: 'Second',
                template: 'row',
                items: ['buttonPhone', 'buttonWhatsapp'],
            },
        ],
    },
    'mh-010-c-0013': {
        title: 'mh-010-c-0013',
        locate: 'centred',
        template: [
            {
                type: 'Main',
                template: '1_logo_2',
                items: ['phone', 'search', 'menu'],
            },
            {
                type: 'Second',
                template: 'column_1',
                items: ['buttonPopup'],
            },
        ],
    },
    'mh-010-c-0014': {
        title: 'mh-010-c-0014',
        locate: 'centred',
        template: [
            {
                type: 'Main',
                template: 'logo_2',
                items: ['cart', 'menu'],
            },
            {
                type: 'Second',
                template: 'button_3',
                items: ['buttonPhone', 'whatsapp', 'telegram', 'email'],
            },
        ],
    },
    'mh-010-c-0015': {
        title: 'mh-010-c-0015',
        locate: 'centred',
        template: [
            {
                type: 'Main',
                template: 'logo_3',
                items: ['account', 'cart', 'menu'],
            },
            {
                type: 'Second',
                template: 'button_3',
                items: ['buttonPopup', 'phone', 'whatsapp', 'email'],
            },
        ],
    },
    'mh-010-o-0001': {
        title: 'mh-010-o-0001',
        locate: 'overlay',
        template: [
            {
                type: 'Main',
                template: '1_logo_1',
                items: ['phone', 'menu'],
            },
        ],
    },
    'mh-010-o-0002': {
        title: 'mh-010-o-0002',
        locate: 'overlay',
        template: [
            {
                type: 'Main',
                template: '1_logo_2',
                items: ['phone', 'cart', 'menu'],
            },
        ],
    },
    'mh-010-o-0003': {
        title: 'mh-010-o-0003',
        locate: 'overlay',
        template: [
            {
                type: 'Main',
                template: '2_logo_2',
                items: ['menu', 'search', 'account', 'cart'],
            },
        ],
    },
    'mh-010-o-0004': {
        title: 'mh-010-o-0004',
        locate: 'overlay',
        template: [
            {
                type: 'Main',
                template: '1_logo_button',
                items: ['menu', 'buttonPopup'],
            },
        ],
    },
    'mh-010-o-0005': {
        title: 'mh-010-o-0005',
        locate: 'overlay',
        template: [
            {
                type: 'Main',
                template: 'logo_button_1',
                items: ['buttonPopup', 'menu'],
            },
        ],
    },
    'mh-010-o-0006': {
        title: 'mh-010-o-0006',
        locate: 'overlay',
        template: [
            {
                type: 'Main',
                template: 'logo_1',
                items: ['menu'],
            },
        ],
    },
    'mh-010-o-0007': {
        title: 'mh-010-o-0007',
        locate: 'overlay',
        template: [
            {
                type: 'Main',
                template: 'logo_2',
                items: ['phone', 'menu'],
            },
        ],
    },
    'mh-010-o-0008': {
        title: 'mh-010-o-0008',
        locate: 'overlay',
        template: [
            {
                type: 'Main',
                template: 'logo_3',
                items: ['account', 'cart', 'menu'],
            },
        ],
    },
    'mh-010-o-0009': {
        title: 'mh-010-o-0009',
        locate: 'overlay',
        template: [
            {
                type: 'Main',
                template: 'logo_4',
                items: ['phone', 'socialsTelegram', 'search', 'menu'],
            },
        ],
    },
    'mh-010-o-0010': {
        title: 'mh-010-o-0010',
        locate: 'overlay',
        template: [
            {
                type: 'Main',
                template: 'logo_5',
                items: ['socialsFacebook', 'socialsInstagram', 'socialsLinkedIn', 'socialsX', 'menu'],
            },
        ],
    },
} as { [key: string]: any };
